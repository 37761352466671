<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-0">
        <div class="row justify-content-around bg-current px-2">
            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-md d-block text-white"><b>Create Individual</b></h2></div>
        </div>
        <div class="card-body p-2">
            <form @submit.prevent="postAssignments" class="row p-2">
                <div class="col-12 form-group mb-3">
                    <label for="">Title</label>
                    <input type="text" v-model="form.title" required placeholder="Enter your assignment title" class="form-control text-grey-900 font-xsss fw-600">                        
                </div>
                <div class="col-12 form-group mb-3">
                    <label for="">Description</label>
                    <textarea rows="1" v-model="form.description" required placeholder="Enter your assignment description" class="form-control text-grey-900 font-xsss fw-600"></textarea>
                </div>
                <div class="col-6 form-group mb-3">
                    <label for="">Term</label>
                    <select required v-model="cycle" @change="setUnit" class="form-control text-grey-900 font-xsss fw-600">
                        <option value="" disabled selected>-- Select Term --</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3 </option>
                    </select>
                </div>
                <div class="col-6 form-group mb-3">
                    <label for="">Unit</label>
                    <input v-if="unit.length == 0" type="text" class="form-control" placeholder="-- Select Unit --" disabled>
                    <select v-else required v-model="form.unit_id" class="form-control text-grey-900 font-xsss fw-600">
                        <option value="" disabled selected>-- Select Unit --</option>
                        <option v-for="(item, index) in unit" :key="index" :value="item.id">
                            {{item.content}}
                        </option>
                    </select>
                </div>
                <div class="col-12 form-group mb-3">
                    <label for="">Due Date</label>
                    <input type="datetime-local" required v-model="form.due_date" placeholder="Enter your assignment due date" class="form-control text-grey-900 font-xsss fw-600">                        
                </div>
                <div class="col-12 form-group mb-3">
                    <hr>
                </div>
                <div class="col-12 form-group text-right my-4">
                    <router-link :to="{name: 'AssignmentsCoursesAdmin', params: {idCourse: paramsId}, hash: '#individual'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                    <input type="Submit" class="btn btn-current" value="Submit">
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            syllabus: [],
            unit: [],
            cycle: '',
            form: {
                title:'',
                description: '',
                syllabus_id: '',
                unit_id: '',
                due_date: ''
            }
        }
    },
    methods:{
        async setUnit(){
            this.form.unit_id = ''
            if(this.cycle != '') {
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus/point?slug=${this.paramsId}&cycle=${this.cycle}` , {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.unit = res.data
                })
            }
        },
        async postAssignments() {
            var data = {
                slug: this.paramsId,
                title: this.form.title,
                description: this.form.description,
                type: 3,
                unit_id: this.form.unit_id,
                due_date: this.form.due_date,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Assignments - Individual !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'AssignmentsCoursesAdmin', hash: '#individual' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Assignments - Individual !',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },

    }
}
</script>
